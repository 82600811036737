/* ==========================================================================
   #OVERLAY
   ========================================================================== */

.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $layer-overlay;
  width: 100%;
  height: 100vh;
  background-color: $color-blue;
  color: $color-white;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition-property: opacity, transform;
  transition-duration: $global-transition-speed;

  .has-overlay-open & {
    opacity: 1;
    pointer-events: all;
  }
}

.c-overlay__inner {
  position: relative;
  z-index: $layer-base;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--header-height, 0) var(--container-gutter);
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.c-overlay__menu {
  --button-font-size: #{$font-size};

  > ul > li {
    opacity: 0;
    transform: translate3d(calc(var(--container-gutter) * -1), 0, 0);

    .has-overlay-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-property: transform, opacity;
      transition-duration: $global-transition-speed;
      transition-timing-function: ease-out;

      @for $i from 1 to 20 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s;
        }
      }
    }
  }
}
