@charset "UTF-8";
/*
  Project: ASICS Digital
  Author: piotr@kulpinski.pl
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Responsive display values
   ========================================================================== */
/* Layers
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Typography
   ========================================================================== */
/* GLOBAL CSS VARIABLES
   ========================================================================== */
:root {
  --column-gutter: clamp(1.6rem, 2.5vw, 2.4rem);
  --container-width: 162rem;
  --container-gutter: clamp(2.4rem, 5vw, 6rem);
  --content-width: 88rem;
}

/* ==========================================================================
   #BREAK WORDS
   ========================================================================== */
/* ==========================================================================
   #BUTTON
   ========================================================================== */
.wp-block-button__link[class], .c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  min-width: var(--button-min-width, 16.5rem);
  padding: var(--button-padding, 0.75em 2em);
  border: 2px solid transparent;
  border-radius: 100vmin;
  font-size: var(--button-font-size, 0.833em);
  font-weight: bold;
  line-height: var(--button-line-height, 1);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: filter 300ms;
}
.wp-block-button__link[class]:not([disabled]):hover, .c-button:not([disabled]):hover {
  filter: brightness(90%);
}

.c-button--primary {
  background-color: var(--button-primary-bg-color, #485cc7);
  border-color: var(--button-primary-bd-color, transparent);
  box-shadow: var(--button-primary-shadow, 0 2px 2px rgba(0, 0, 0, 0.35));
  color: var(--button-primary-color, #fff);
}

.c-button--secondary {
  background-color: var(--button-primary-bg-color, #001e62);
  border-color: var(--button-primary-bd-color, transparent);
  box-shadow: var(--button-primary-shadow, 0 2px 2px rgba(0, 0, 0, 0.35));
  color: var(--button-primary-color, #fff);
}

.c-button--tertiary {
  background-color: var(--button-tertiary-bg-color, #fff);
  border-color: var(--button-tertiary-bd-color, #001e62);
  color: var(--button-tertiary-color, #001e62);
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.o-container {
  width: 100%;
  max-width: min(var(--container-width), 100% - var(--container-gutter) * 2);
  margin-right: auto;
  margin-left: auto;
}

/* ==========================================================================
   #COPY
   ========================================================================== */
.c-article h1:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h2:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h3:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h4:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h5:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h6:not(.c-heading):not(:first-child) {
  margin-top: 1.2em;
}
.c-article h1:not(.c-heading):not(:last-child) {
  margin-bottom: 0.9em;
}
.c-article h1:not(.c-heading):not(:first-child) {
  margin-top: 0.3em;
}
.c-article h2:not(.c-heading):not(:last-child) {
  margin-bottom: 0.6em;
}
.c-article h3:not(.c-heading):not(:last-child) {
  margin-bottom: 1.2em;
}
.c-article h4:not(.c-heading):not(:first-child) {
  margin-top: 1.8em;
}
.c-article ul,
.c-article ol {
  margin-left: 1em;
}
.c-article ul li,
.c-article ol li {
  padding-left: 0.5em;
}
.c-article ul {
  list-style: disc;
}
.c-article ol {
  list-style-type: decimal;
}
.c-article img {
  max-width: 100%;
  height: auto;
}
.c-article iframe {
  display: block;
  width: 100%;
}
.c-article em {
  font-style: italic;
}
.c-article strong {
  font-weight: 700;
}
.c-article mark {
  background-color: #485cc7;
}
.c-article p,
.c-article ul,
.c-article ol,
.c-article address {
  margin-top: 0;
  margin-bottom: 0;
}
.c-article p:not(:empty):not(:first-child),
.c-article ul:not(:empty):not(:first-child),
.c-article ol:not(:empty):not(:first-child),
.c-article address:not(:empty):not(:first-child) {
  margin-top: 1.2em;
}
.c-article li:not(:first-child) {
  margin-top: 0.9em;
}
.c-article a:not([class]):hover,
.c-article a[class=""]:hover {
  color: #485cc7;
}
.c-article [class*=wp-block] figcaption:not(:first-child) {
  margin-top: 1.2em;
}

/* ==========================================================================
   #ELLIPSIS
   ========================================================================== */
/* ==========================================================================
   #FORMS
   ========================================================================== */
/* ==========================================================================
   #HEADING
   ========================================================================== */
.c-popup__close, .c-heading, .c-article h1:not(.c-heading), .c-article h2:not(.c-heading), .c-article h3:not(.c-heading), .c-article h4:not(.c-heading), .c-article h5:not(.c-heading), .c-article h6:not(.c-heading) {
  font-weight: 700;
  font-size: clamp(var(--minFontSize), var(--scaler, 1vw), var(--maxFontSize));
}

.c-heading--h1, .c-article h1:not(.c-heading) {
  --minFontSize: 4.2rem;
  --maxFontSize: 10.8rem;
  --scaler: 7.5vw;
  line-height: 1.05;
}

.c-heading--h2, .c-article h2:not(.c-heading) {
  --minFontSize: 3.6rem;
  --maxFontSize: 5.4rem;
  --scaler: 5.4vw;
  line-height: 1.1;
}

.c-popup__close, .c-heading--h3, .c-article h3:not(.c-heading) {
  --minFontSize: 3rem;
  --maxFontSize: 4.2rem;
  --scaler: 4.2vw;
  line-height: 1.15;
}

.c-heading--h4, .c-article h4:not(.c-heading) {
  --minFontSize: 2.4rem;
  --maxFontSize: 3rem;
  --scaler: 3vw;
  line-height: 1.2;
}

.c-heading--h5, .c-article h5:not(.c-heading) {
  --minFontSize: 1.8rem;
  --maxFontSize: 1.8rem;
  line-height: 1.22;
}

.c-heading--h6, .c-article h6:not(.c-heading) {
  --minFontSize: 1.5rem;
  --maxFontSize: 1.5rem;
  line-height: 1.33;
}

/* ==========================================================================
   #ICON
   ========================================================================== */
/* ==========================================================================
   #WRAPPER
   ========================================================================== */
.c-header__inner, .c-head, .c-footer__inner, .c-bottom__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
}

.c-footer__inner, .c-bottom__inner {
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .c-footer__inner, .c-bottom__inner {
    flex-direction: row;
    justify-content: space-between;
  }
}

.c-lead {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .c-lead {
    max-width: 82.5%;
  }
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
@font-face {
  font-family: "ASICS Font 3.0";
  font-weight: normal;
  font-display: swap;
  src: url("~assets/fonts/ASICSFont3.0-Regular.woff2") format("woff2"), url("~assets/fonts/ASICSFont3.0-Regular.woff") format("woff");
}
@font-face {
  font-family: "ASICS Font 3.0";
  font-weight: bold;
  font-display: swap;
  src: url("~assets/fonts/ASICSFont3.0-Bold.woff2") format("woff2"), url("~assets/fonts/ASICSFont3.0-Bold.woff") format("woff");
}
/* ==========================================================================
   #RESET
   ========================================================================== */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
}

:root {
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a,
button,
[type=button] {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition-duration: 300ms;
  transition-property: color, background-color, border, outline, box-shadow, opacity, outline, transform;
}
a:focus,
button:focus,
[type=button]:focus {
  outline: none;
}

button,
[type=button] {
  background: none;
  border: 0;
  border-radius: 0;
  appearance: none;
}

ul,
ol {
  list-style: none;
}

/* ==========================================================================
   #VENDOR - FLICKITY
   ========================================================================== */
/* prevent page scrolling when flickity is fullscreen */
.is-flickity-fullscreen {
  overflow: hidden;
}

.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
}
.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.25s;
}
.is-fullscreen .flickity-viewport {
  transition: none;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #BODY
   ========================================================================== */
.o-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  color: #001e62;
  font-family: "ASICS Font 3.0", -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.2;
}
.o-body::after {
  content: "";
  position: fixed;
  inset: 0;
  z-index: 24;
  background-color: rgba(0, 30, 98, 0.85);
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(5px);
  transform: translateX(-300%);
  transition-property: opacity, transform;
  transition-duration: 600ms, 0s;
  transition-delay: 0s 600ms;
}
.o-body.has-popup-open::after {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  transition-delay: 0s;
}
.o-body.has-overlay-open::after {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  transition-delay: 0s;
}
.o-body.is-loading::after {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  transition-delay: 0s;
}
.o-body.is-loading::before {
  content: "";
  position: fixed;
  inset: 0;
  z-index: 25;
  background-image: url("~assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
/* ==========================================================================
   #GRID
   ========================================================================== */
.o-grid {
  display: grid;
  gap: var(--column-gutter);
}
@media (min-width: 0) {
  .o-grid--xs-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}

.o-grid__item {
  width: 100%;
}
.o-grid__item--full {
  grid-column: 1/-1;
}
@media (min-width: 0) {
  .o-grid__item--xs-1 {
    grid-column: span 1;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-2 {
    grid-column: span 2;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-3 {
    grid-column: span 3;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-4 {
    grid-column: span 4;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-5 {
    grid-column: span 5;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-6 {
    grid-column: span 6;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-1 {
    grid-column: span 1;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-2 {
    grid-column: span 2;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-3 {
    grid-column: span 3;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-4 {
    grid-column: span 4;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-5 {
    grid-column: span 5;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-6 {
    grid-column: span 6;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-1 {
    grid-column: span 1;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-2 {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-3 {
    grid-column: span 3;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-4 {
    grid-column: span 4;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-5 {
    grid-column: span 5;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-6 {
    grid-column: span 6;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-2 {
    grid-column: span 2;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-3 {
    grid-column: span 3;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-4 {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-5 {
    grid-column: span 5;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-6 {
    grid-column: span 6;
  }
}

/* ==========================================================================
   #SECTION
   ========================================================================== */
.o-section {
  position: relative;
  padding-top: clamp(
    6rem,
    5 * 2vw,
    12rem
  );
  padding-bottom: clamp(
    6rem,
    5 * 2vw,
    12rem
  );
}
.o-section--pt-0 {
  padding-top: 0;
}
.o-section--pb-0 {
  padding-bottom: 0;
}
.o-section--style-white {
  background-color: #fff;
}
.o-section--style-indigo {
  background-color: #e4e5f3;
}
.o-section--style-gradient-top {
  background-image: linear-gradient(to top, #e4e5f3, #fff);
}
.o-section--style-gradient-bottom {
  background-image: linear-gradient(to bottom, #e4e5f3, #fff);
}
.o-section--style-gradient-shape {
  background-image: linear-gradient(to bottom, #e4e5f3, #fff);
}
@media (min-width: 768px) {
  .o-section--style-gradient-shape {
    background-color: #e4e5f3;
    background-image: url("~assets/images/separator.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.o-section--style-hero {
  background-image: linear-gradient(135deg, #ffecdc 0%, #fff 42%, #e4e5f3 70%, #b6b8dc 100%);
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
.o-wrapper {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

/* ==========================================================================
   #ARTICLE
   ========================================================================== */
.c-article {
  width: 100%;
  max-width: var(--content-width);
  margin-right: auto;
  margin-left: auto;
}
.c-article .alignwide {
  width: calc(100vw - var(--scrollbar-width, 0px) - calc(var(--container-gutter) * 2));
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: var(--container-width);
}
.acf-block-preview .c-article .alignwide {
  width: auto;
  margin-left: 0;
  transform: none;
}
.c-article .alignwide.wp-block-separator, .c-article .alignwide.wp-block-acf-hero, .c-article .alignwide.wp-block-acf-products, .c-article .alignwide.wp-block-acf-section {
  width: calc(100vw - var(--scrollbar-width, 0px) - calc(var(--container-gutter) * 2));
  margin-left: 50%;
  transform: translateX(-50%);
}
.acf-block-preview .c-article .alignwide.wp-block-separator, .acf-block-preview .c-article .alignwide.wp-block-acf-hero, .acf-block-preview .c-article .alignwide.wp-block-acf-products, .acf-block-preview .c-article .alignwide.wp-block-acf-section {
  width: auto;
  margin-left: 0;
  transform: none;
}
.c-article .alignfull {
  width: calc(100vw - var(--scrollbar-width, 0px) - calc(var(--container-gutter) * 2));
  margin-left: 50%;
  transform: translateX(-50%);
}
.acf-block-preview .c-article .alignfull {
  width: auto;
  margin-left: 0;
  transform: none;
}
.c-article .alignfull.wp-block-separator, .c-article .alignfull.wp-block-acf-hero, .c-article .alignfull.wp-block-acf-products, .c-article .alignfull.wp-block-acf-section {
  width: calc(100vw - var(--scrollbar-width, 0px) );
  margin-left: 50%;
  transform: translateX(-50%);
}
.acf-block-preview .c-article .alignfull.wp-block-separator, .acf-block-preview .c-article .alignfull.wp-block-acf-hero, .acf-block-preview .c-article .alignfull.wp-block-acf-products, .acf-block-preview .c-article .alignfull.wp-block-acf-section {
  width: auto;
  margin-left: 0;
  transform: none;
}

/* ==========================================================================
   #BENEFIT
   ========================================================================== */
.c-benefit {
  display: flex;
  align-items: center;
  gap: clamp(
    1.596rem,
    1.33 * 2vw,
    3.192rem
  );
  padding: 2.4rem;
  background-color: #fff;
  border-radius: 2.4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}
@media (min-width: 1024px) {
  .c-benefit {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }
}

.c-benefit__icon {
  font-size: 3.33em;
}
@media (min-width: 1024px) {
  .c-benefit__icon {
    font-size: 5.33em;
  }
}
.c-benefit__icon svg {
  height: 1em;
  width: 1em;
}

.c-benefit__content {
  flex: 1;
}

.c-benefit__description {
  font-size: 1.3rem;
  line-height: 1.33;
}
.c-benefit__description:not(:first-child) {
  margin-top: 0.792rem;
}
@media (min-width: 768px) {
  .c-benefit__description {
    font-size: 1.5rem;
  }
}

/* ==========================================================================
   #BOTTOM
   ========================================================================== */
.c-bottom {
  padding-top: clamp(
    0.6rem,
    0.5 * 2vw,
    1.2rem
  );
  padding-bottom: clamp(
    0.6rem,
    0.5 * 2vw,
    1.2rem
  );
  background-color: #001e62;
  color: #fff;
  font-size: 1.2rem;
}

.c-bottom__inner {
  row-gap: clamp(
    1.2rem,
    1 * 2vw,
    2.4rem
  );
}

/* ==========================================================================
   #BUTTON
   ========================================================================== */
/* ==========================================================================
   #CARD
   ========================================================================== */
.c-card {
  display: flex;
  flex-direction: column;
  gap: 1.584rem;
  flex: 1;
  padding: clamp(1.584rem, 2.4vw, 2.4rem);
  background-color: #e4e5f3;
  border-radius: 2.4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
  overflow: hidden;
}
.c-card[href]:hover {
  background-color: #d2d4eb;
}

.c-card__image {
  aspect-ratio: 3/2;
  border-radius: 2.4rem;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .c-card__image {
    aspect-ratio: 5/4;
  }
}
.c-card__image *[src] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-card__author {
  font-size: 1.3rem;
  text-transform: uppercase;
}
.c-card__author + * {
  margin-top: -0.792rem;
}

.c-card__excerpt {
  font-size: 1.5rem;
  line-height: 1.33;
}

/* ==========================================================================
   #CAROUSEL
   ========================================================================== */
.c-carousel {
  display: grid;
  gap: var(--column-gutter);
}
@media (min-width: 540px) {
  .c-carousel {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 768px) {
  .c-carousel {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .c-carousel {
    grid-template-columns: repeat(3, 1fr);
  }
}
.c-carousel.flickity-enabled {
  display: block;
  width: calc(100% + var(--column-gutter));
  margin: calc(var(--column-gutter) * -0.5);
}
.c-carousel .flickity-viewport {
  overflow: visible;
  transition: height 0s;
}

.c-carousel__item {
  display: flex;
}
.flickity-enabled .c-carousel__item {
  width: calc(75vw - var(--container-gutter) * 2 + var(--column-gutter));
  max-width: calc(28rem + var(--column-gutter));
  padding: calc(var(--column-gutter) * 0.5);
}
@media (min-width: 1024px) {
  .flickity-enabled .c-carousel__item {
    width: 33.333%;
  }
}
.flickity-resize .c-carousel__item {
  min-height: 100%;
}

/* ==========================================================================
   #CTA
   ========================================================================== */
.c-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4.8rem;
  text-align: center;
}
@media (min-width: 768px) {
  .c-cta {
    flex-direction: row;
    text-align: left;
  }
}

.c-cta__image {
  width: 100%;
  max-width: 37.5rem;
}
@media (min-width: 768px) {
  .c-cta__image {
    width: 42.5%;
  }
}

.c-cta__content {
  flex: 1;
}
@media (min-width: 768px) {
  .c-cta__content {
    max-width: 42rem;
  }
}

.c-cta__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.2rem 2.4rem;
}
.c-cta__buttons:not(:first-child) {
  margin-top: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
}
@media (min-width: 768px) {
  .c-cta__buttons {
    justify-content: start;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  --button-primary-bg-color: #fff;
  --button-primary-color: #001e62;
  --button-secondary-bg-color: #fff;
  --button-secondary-color: #001e62;
  --button-tertiary-bg-color: #001e62;
  --button-tertiary-bd-color: #fff;
  --button-tertiary-color: #fff;
  margin-top: auto;
  padding-top: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
  padding-bottom: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
  background-color: #001e62;
  color: #fff;
}

.c-footer__links {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.2em;
}
@media (min-width: 768px) {
  .c-footer__links {
    align-items: start;
  }
}

.c-footer__menu {
  flex: 1;
}

/* ==========================================================================
   #GALLERY
   ========================================================================== */
.c-gallery {
  display: grid;
  gap: var(--column-gutter);
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}
@media (min-width: 768px) {
  .c-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .c-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-gallery__image {
  aspect-ratio: 3/2;
}
.c-gallery__image picture,
.c-gallery__image img {
  width: 100%;
  height: 100%;
}
.c-gallery__image [src] {
  object-fit: cover;
}
@media (min-width: 1024px) {
  .c-gallery__image {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
  }
  .c-gallery__image:nth-child(4n+2), .c-gallery__image:nth-child(4n+3) {
    grid-column: span 2;
    aspect-ratio: 2/1;
  }
}

/* ==========================================================================
   #HEAD
   ========================================================================== */
.c-head {
  text-align: center;
}
@media (min-width: 768px) {
  .c-head--left {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .c-head--center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .c-head--right {
    text-align: right;
  }
}

.c-head__title {
  width: 100%;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: sticky;
  top: 0;
  z-index: 16;
  padding-top: clamp(
    1.5rem,
    1.25 * 2vw,
    3rem
  );
  padding-bottom: clamp(
    1.5rem,
    1.25 * 2vw,
    3rem
  );
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 0 0 #e4e5f3;
  backdrop-filter: blur(5px);
}
@media (max-width: 767.98px) {
  .has-overlay-open .c-header {
    color: #fff;
  }
}

.c-header__inner {
  position: relative;
  z-index: 16;
}

@media (max-width: 767.98px) {
  .has-overlay-open .c-header__hamburger {
    color: #b6b8dc;
  }
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
.c-heading--thin {
  font-weight: normal;
}

/* Size variants
   ========================================================================== */
/* ==========================================================================
   #HERO
   ========================================================================== */
/* ==========================================================================
   #LABEL
   ========================================================================== */
.c-label {
  color: var(--label-color, #485cc7);
  font-size: 1.1rem;
  line-height: 1.5;
  letter-spacing: 0.5em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .c-label {
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
@media (min-width: 1024px) {
  .c-label--lg {
    font-size: 1.8rem;
    line-height: 1.22;
  }
}

/* ==========================================================================
   #LEAD
   ========================================================================== */
.c-lead {
  display: flex;
  flex-direction: column;
}
.c-lead--left {
  align-items: start;
  text-align: left;
}
.c-lead--center {
  align-items: center;
  text-align: center;
}
.c-lead--right {
  align-items: end;
  text-align: right;
}

.c-lead__title:not(:first-child) {
  margin-top: 0.792rem;
}

.c-lead__description {
  font-size: clamp(1.8rem, 3vw, 3rem);
  line-height: 1.2;
}
.c-lead__description:not(:first-child) {
  margin-top: clamp(
    1.2rem,
    1 * 2vw,
    2.4rem
  );
}

.c-lead__button:not(:first-child) {
  margin-top: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
}

/* ==========================================================================
   #LOGO
   ========================================================================== */
.c-logo {
  display: inline-block;
}
.c-logo:hover {
  opacity: 0.75;
}
.c-logo--text {
  font-weight: 500;
  letter-spacing: 0.125em;
}
.c-logo--image svg {
  display: block;
  height: 1em;
}
.c-logo--image * {
  fill: currentColor;
  stroke: none;
}
.c-logo--md {
  font-size: clamp(2.4rem, 5vw, 4rem);
}
.c-logo--lg {
  font-size: 4rem;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
.c-media {
  width: 100%;
}
.c-media--1-1 {
  aspect-ratio: 1/1;
}
.c-media--3-2 {
  aspect-ratio: 3/2;
}
.c-media--16-9 {
  aspect-ratio: 16/9;
}

.c-media__object {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.c-media__object[href]:hover, .c-media__object[data-popup]:hover {
  opacity: 0.9;
}
.c-media__object picture {
  display: contents;
}
.c-media__object img,
.c-media__object video,
.c-media__object iframe {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}
.c-media__object--cover img,
.c-media__object--cover video,
.c-media__object--cover iframe {
  object-fit: cover;
}
.c-media__object--contain img,
.c-media__object--contain video,
.c-media__object--contain iframe {
  object-fit: contain;
}

.c-media__play {
  position: absolute;
  z-index: 1;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: clamp(4.8rem, 6vw, 6rem);
  transition: transform 300ms;
}
button:hover .c-media__play {
  transform: scale(1.1);
}
.c-media__play .u-icon {
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.35));
}

/* ==========================================================================
   #MENU - PRIMARY
   ========================================================================== */
.c-menu-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  ) clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
}
@media (min-width: 768px) {
  .c-menu-primary {
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .c-menu-primary {
    --button-primary-bg-color: #fff;
    --button-primary-color: #001e62;
    --button-padding: 0.888em 1.666em;
  }
}

@media (min-width: 768px) {
  .c-menu-primary__link {
    font-weight: bold;
  }
}
@media (max-width: 767.98px) {
  .c-menu-primary__link[class*=button] {
    margin-top: 1.5em;
  }
}
.c-menu-primary__link:not([class*=button]) {
  font-size: 3rem;
}
@media (min-width: 768px) {
  .c-menu-primary__link:not([class*=button]) {
    font-size: 1em;
  }
}
.c-menu-primary__link:not([class*=button]):hover {
  opacity: 0.75;
}

/* ==========================================================================
   #MENU - SECONDARY
   ========================================================================== */
.c-menu-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.792em 1.2em;
}
@media (min-width: 768px) {
  .c-menu-secondary {
    flex-direction: row-reverse;
    justify-content: end;
  }
}

.c-menu-secondary__link:not([class*=button]):hover {
  opacity: 0.75;
}

/* ==========================================================================
   #MENU - TERTIARY
   ========================================================================== */
.c-menu-tertiary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.3em 1.8em;
}

.c-menu-tertiary__link:hover {
  opacity: 0.75;
}

/* ==========================================================================
   #OVERLAY
   ========================================================================== */
.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100vh;
  background-color: #485cc7;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition-property: opacity, transform;
  transition-duration: 300ms;
}
.has-overlay-open .c-overlay {
  opacity: 1;
  pointer-events: all;
}

.c-overlay__inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--header-height, 0) var(--container-gutter);
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.c-overlay__menu {
  --button-font-size: 1.8rem;
}
.c-overlay__menu > ul > li {
  opacity: 0;
  transform: translate3d(calc(var(--container-gutter) * -1), 0, 0);
}
.has-overlay-open .c-overlay__menu > ul > li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(1) {
  transition-delay: 0.1s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(2) {
  transition-delay: 0.2s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(3) {
  transition-delay: 0.3s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(4) {
  transition-delay: 0.4s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(5) {
  transition-delay: 0.5s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(6) {
  transition-delay: 0.6s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(7) {
  transition-delay: 0.7s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(8) {
  transition-delay: 0.8s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(9) {
  transition-delay: 0.9s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(10) {
  transition-delay: 1s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(11) {
  transition-delay: 1.1s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(12) {
  transition-delay: 1.2s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(13) {
  transition-delay: 1.3s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(14) {
  transition-delay: 1.4s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(15) {
  transition-delay: 1.5s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(16) {
  transition-delay: 1.6s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(17) {
  transition-delay: 1.7s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(18) {
  transition-delay: 1.8s;
}
.has-overlay-open .c-overlay__menu > ul > li:nth-child(19) {
  transition-delay: 1.9s;
}

/* ==========================================================================
   #PANEL
   ========================================================================== */
.c-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4.8rem;
}
@media (min-width: 540px) {
  .c-panel {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .c-panel {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .c-panel--left {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .c-panel--right {
    flex-direction: row-reverse;
  }
}

.c-panel__image {
  width: 100%;
  max-width: 42.5rem;
  position: relative;
}
@media (min-width: 768px) {
  .c-panel__image {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .c-panel__image--contain {
    aspect-ratio: 1/1;
  }
  .c-panel__image--contain img,
.c-panel__image--contain video,
.c-panel__image--contain iframe {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.c-panel__content {
  flex: 1;
}
@media (min-width: 768px) {
  .c-panel__content {
    max-width: 35rem;
  }
}

/* ==========================================================================
   #POPUP
   ========================================================================== */
.c-popup {
  position: fixed;
  inset: 0;
  z-index: 25;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(-300%);
  transition: transform 0s 300ms;
}
.has-popup-open .c-popup {
  transform: translateX(0);
  pointer-events: all;
  transition: none;
}

.c-popup__close {
  position: absolute;
  top: calc(var(--container-gutter) + 1em);
  right: calc(var(--container-gutter) + 1em);
  z-index: 1;
  padding: 1em;
  margin: -1em;
}
[data-layout=video] .c-popup__close {
  font-size: clamp(
    1.8rem,
    1.5 * 2vw,
    3.6rem
  );
}

.c-popup__content {
  position: absolute;
  inset: 50% auto auto 50%;
  opacity: 0;
  margin-top: 5%;
  color: #fff;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 450ms, margin 450ms;
}
.has-popup-open .c-popup__content {
  opacity: 1;
  margin-top: 0;
}
.c-popup__content[data-layout=content] {
  width: calc(100% - var(--container-gutter) * 2);
  max-height: calc(100% - var(--container-gutter) * 2);
  max-width: calc(var(--content-width) + 2 * var(--container-gutter));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.c-popup__content[data-layout=video] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: calc(var(--container-width) + 2 * var(--container-gutter));
  padding: var(--container-gutter);
}

[data-layout=video] .c-popup__inner, [data-layout=video] .c-popup__inner [data-popup-video] {
  display: contents;
}
[data-layout=video] .c-popup__inner iframe,
[data-layout=video] .c-popup__inner video, [data-layout=video] .c-popup__inner [data-popup-video] iframe,
[data-layout=video] .c-popup__inner [data-popup-video] video {
  max-height: 100%;
  max-width: 100%;
}

/* ==========================================================================
   #SOCIALS
   ========================================================================== */
.c-socials {
  display: flex;
  gap: 0.75em;
}

.c-socials__item {
  flex-shrink: 0;
}

.c-socials__link {
  display: flex;
  align-items: center;
  padding: 0.25em;
  background-color: #fff;
  border-radius: 50%;
  color: #001e62;
  font-size: 1.2em;
  line-height: 1;
}
@media (min-width: 1024px) {
  .c-socials__link {
    font-size: 1.33em;
  }
}
.c-socials__link:hover {
  opacity: 0.75;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
.c-table {
  margin-top: clamp(
    3rem,
    2.5 * 2vw,
    6rem
  );
  margin-bottom: clamp(
    3rem,
    2.5 * 2vw,
    6rem
  );
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
}

.c-table__row {
  border-bottom: 1px solid #ccc;
}

.c-table__cell {
  padding: clamp(1.584rem, 2.4vw, 2.4rem) 0;
  text-align: left;
  vertical-align: middle;
}
.c-table__cell:not(:first-child) {
  padding-left: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
  text-align: right;
  white-space: nowrap;
}
.c-table__cell--dimmed {
  color: #6a6a6a;
  font-size: 0.833em;
}
@media (min-width: 1024px) {
  .c-table__cell--dimmed {
    font-size: 1em;
  }
}
.c-table__cell--cta {
  color: #001e62;
}

.c-table__icon {
  display: block;
  width: 1em;
  height: 1em;
}

/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */
.c-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-testimonial__content:not(:first-child) {
  margin-top: 3.192rem;
}

.c-testimonial__quote {
  font-size: clamp(1.8rem, 3vw, 3rem);
  line-height: 1.2;
}

/* ==========================================================================
   #TILE
   ========================================================================== */
.c-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.584rem;
  flex: 1;
  padding: clamp(2.4rem, 3.6vw, 3.6rem);
  background-color: #485cc7;
  border-radius: 2.4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
  color: #fff;
  text-align: center;
  overflow: hidden;
}
.c-tile[href]:hover {
  background-color: #3a4ebc;
}

.c-tile__icon {
  width: 1em;
  height: 1em;
  font-size: 4rem;
}

.c-tile__description {
  font-size: 1.5rem;
  line-height: 1.33;
  text-align: left;
}
.c-tile__description:not(:last-child) {
  margin-bottom: 0.6rem;
}

.c-tile__link {
  margin-top: auto;
  transition: color 300ms;
}
[href]:hover .c-tile__link {
  color: #b6b8dc;
}

/* ==========================================================================
   #VALUES
   ========================================================================== */
.c-values {
  display: flex;
  gap: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
  padding: clamp(
    3rem,
    2.5 * 2vw,
    6rem
  ) clamp(
    3.6rem,
    3 * 2vw,
    7.2rem
  );
  background-color: #fff;
  border-radius: 4.8rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}

.c-values__separator {
  width: 1px;
  background-color: #ccc;
}

.c-values__nav {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 0.792rem;
  flex-basis: 27.5%;
  text-align: right;
}

.c-values__nav-item {
  font-size: 1.5rem;
  line-height: 1.33;
  opacity: 0.5;
}
.c-values__nav-item:hover {
  opacity: 0.75;
}
.c-values__nav-item.is-active {
  opacity: 1;
}

.c-values__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) {
  .c-values__content {
    align-items: start;
    text-align: left;
  }
}

.c-values__carousel {
  width: 100%;
  margin-left: calc(var(--column-gutter) * -1);
}
.c-values__carousel:not(:first-child) {
  margin-top: 1.584rem;
}

.c-values__item {
  display: flex;
  flex-direction: column;
  gap: 1.584rem;
  width: 100%;
  padding-left: var(--column-gutter);
}

.c-values__buttons {
  display: flex;
  gap: var(--column-gutter);
}
.c-values__buttons:not(:first-child) {
  margin-top: 3.192rem;
}

.c-values__button {
  padding: 0.125em;
  background-color: #fff;
  border: 2px solid;
  border-radius: 50%;
  font-size: 1.5em;
  transition: filter 300ms;
}
.c-values__button:hover {
  filter: brightness(90%);
}

/* ==========================================================================
   #Button
   ========================================================================== */
.wp-block-button__link[class] {
  min-width: 16rem;
}
.is-style-outline .wp-block-button__link[class]:not(.has-color) {
  color: #485cc7;
}
.is-style-outline .wp-block-button__link[class]:not(.has-color):hover {
  color: #fff;
}
.wp-block-button__link[class]:not(.has-background) {
  border-color: #485cc7;
}

/* ==========================================================================
   #COLUMNS
   ========================================================================== */
.wp-block-columns {
  row-gap: calc(var(--column-gutter) * 2);
  column-gap: var(--column-gutter);
}

.wp-block-column[class]:not(:first-child) {
  margin-left: 0;
}

/* ==========================================================================
   #COMMON
   ========================================================================== */
.wp-block-buttons[class],
.wp-block-code[class],
.wp-block-table[class],
.wp-block-latest-posts .wp-block-subhead[class],
.wp-block-categories[class],
.wp-block-verse[class],
.wp-block-preformatted[class] {
  margin-top: 0;
  margin-bottom: 0;
}
.wp-block-buttons[class]:not(:first-child),
.wp-block-code[class]:not(:first-child),
.wp-block-table[class]:not(:first-child),
.wp-block-latest-posts .wp-block-subhead[class]:not(:first-child),
.wp-block-categories[class]:not(:first-child),
.wp-block-verse[class]:not(:first-child),
.wp-block-preformatted[class]:not(:first-child) {
  margin-top: clamp(
    2.4rem,
    2 * 2vw,
    4.8rem
  );
}

.wp-block-image[class],
.wp-block-cover[class],
.wp-block-gallery[class],
.wp-block-embed[class],
.wp-block-quote[class],
.wp-block-pullquote[class],
.wp-block-columns[class],
.wp-block-text-columns[class],
.wp-block-separator[class] {
  margin-top: 0;
  margin-bottom: 0;
}
.wp-block-image[class]:not(:first-child),
.wp-block-cover[class]:not(:first-child),
.wp-block-gallery[class]:not(:first-child),
.wp-block-embed[class]:not(:first-child),
.wp-block-quote[class]:not(:first-child),
.wp-block-pullquote[class]:not(:first-child),
.wp-block-columns[class]:not(:first-child),
.wp-block-text-columns[class]:not(:first-child),
.wp-block-separator[class]:not(:first-child) {
  margin-top: clamp(
    4.8rem,
    4 * 2vw,
    9.6rem
  );
}
.wp-block-image[class]:not(:last-child),
.wp-block-cover[class]:not(:last-child),
.wp-block-gallery[class]:not(:last-child),
.wp-block-embed[class]:not(:last-child),
.wp-block-quote[class]:not(:last-child),
.wp-block-pullquote[class]:not(:last-child),
.wp-block-columns[class]:not(:last-child),
.wp-block-text-columns[class]:not(:last-child),
.wp-block-separator[class]:not(:last-child) {
  margin-bottom: clamp(
    4.8rem,
    4 * 2vw,
    9.6rem
  );
}

.wp-block-acf-benefits[class],
.wp-block-acf-cta[class],
.wp-block-acf-gallery[class],
.wp-block-acf-head[class],
.wp-block-acf-jobs[class],
.wp-block-acf-lead[class],
.wp-block-acf-media[class],
.wp-block-acf-panel[class],
.wp-block-acf-posts[class],
.wp-block-acf-testimonial[class],
.wp-block-acf-tiles[class],
.wp-block-acf-values[class] {
  margin-top: 0;
  margin-bottom: 0;
}
.wp-block-acf-benefits[class]:not(:first-child),
.wp-block-acf-cta[class]:not(:first-child),
.wp-block-acf-gallery[class]:not(:first-child),
.wp-block-acf-head[class]:not(:first-child),
.wp-block-acf-jobs[class]:not(:first-child),
.wp-block-acf-lead[class]:not(:first-child),
.wp-block-acf-media[class]:not(:first-child),
.wp-block-acf-panel[class]:not(:first-child),
.wp-block-acf-posts[class]:not(:first-child),
.wp-block-acf-testimonial[class]:not(:first-child),
.wp-block-acf-tiles[class]:not(:first-child),
.wp-block-acf-values[class]:not(:first-child) {
  margin-top: clamp(
    6rem,
    5 * 2vw,
    12rem
  );
}
.wp-block-acf-benefits[class]:not(:last-child),
.wp-block-acf-cta[class]:not(:last-child),
.wp-block-acf-gallery[class]:not(:last-child),
.wp-block-acf-head[class]:not(:last-child),
.wp-block-acf-jobs[class]:not(:last-child),
.wp-block-acf-lead[class]:not(:last-child),
.wp-block-acf-media[class]:not(:last-child),
.wp-block-acf-panel[class]:not(:last-child),
.wp-block-acf-posts[class]:not(:last-child),
.wp-block-acf-testimonial[class]:not(:last-child),
.wp-block-acf-tiles[class]:not(:last-child),
.wp-block-acf-values[class]:not(:last-child) {
  margin-bottom: clamp(
    6rem,
    5 * 2vw,
    12rem
  );
}
.wp-block-acf-head + .wp-block-acf-benefits:not(:first-child),
.wp-block-acf-head + .wp-block-acf-cta:not(:first-child),
.wp-block-acf-head + .wp-block-acf-gallery:not(:first-child),
.wp-block-acf-head + .wp-block-acf-head:not(:first-child),
.wp-block-acf-head + .wp-block-acf-jobs:not(:first-child),
.wp-block-acf-head + .wp-block-acf-lead:not(:first-child),
.wp-block-acf-head + .wp-block-acf-media:not(:first-child),
.wp-block-acf-head + .wp-block-acf-panel:not(:first-child),
.wp-block-acf-head + .wp-block-acf-posts:not(:first-child),
.wp-block-acf-head + .wp-block-acf-testimonial:not(:first-child),
.wp-block-acf-head + .wp-block-acf-tiles:not(:first-child),
.wp-block-acf-head + .wp-block-acf-values:not(:first-child) {
  margin-top: clamp(
    -7.2rem,
    -3 * 2vw,
    -3.6rem
  );
}
.wp-block-acf-lead.alignwide + .wp-block-acf-benefits:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-cta:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-gallery:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-head:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-jobs:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-lead:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-media:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-panel:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-posts:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-testimonial:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-tiles:not(:first-child),
.wp-block-acf-lead.alignwide + .wp-block-acf-values:not(:first-child) {
  margin-top: clamp(
    -6rem,
    -2.5 * 2vw,
    -3rem
  );
}

@media (min-width: 768px) {
  .wp-block-acf-panel + .wp-block-acf-panel[class]:not(:first-child) {
    margin-top: clamp(
    -6rem,
    -2.5 * 2vw,
    -3rem
  );
  }
}

.wp-block-acf-jobs {
  overflow: hidden;
}

/* ==========================================================================
   #DEMO
   ========================================================================== */
.wp-block-acf-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==========================================================================
   #Embed
   ========================================================================== */
.wp-block-embed .wp-block-embed__wrapper {
  position: relative;
}
.wp-block-embed .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.wp-block-embed .wp-block-embed__wrapper > * {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #Image
   ========================================================================== */
.wp-block-image.alignnone, .wp-block-image.aligncenter, .wp-block-image.alignright, .wp-block-image.alignleft {
  max-width: 100%;
  clear: both;
  margin: 0;
}
.wp-block-image.alignnone:not(:first-child), .wp-block-image.aligncenter:not(:first-child), .wp-block-image.alignright:not(:first-child), .wp-block-image.alignleft:not(:first-child) {
  margin-top: 1.2em;
}
.wp-block-image.alignright, .wp-block-image.alignleft {
  max-width: 45%;
  margin-top: 0;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .wp-block-image.alignright, .wp-block-image.alignleft {
    margin-bottom: 2em;
  }
}
.wp-block-image.alignright {
  float: right;
  margin-left: 2em;
}
.wp-block-image.alignleft {
  float: left;
  margin-right: 2em;
}
@media (min-width: 768px) {
  .wp-block-image.alignleft {
    margin-right: 10%;
    margin-left: calc(var(--container-gutter) * -1);
  }
}
.wp-block-image.aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image.alignwide:first-child, .wp-block-image.alignfull:first-child {
  margin-top: calc(var(--spacer-top) * -1);
}
.wp-block-image.alignwide:first-child::before, .wp-block-image.alignfull:first-child::before {
  content: "";
  display: block;
  height: 1px;
  width: calc(100vw - var(--scrollbar-width, 0px));
  position: absolute;
  bottom: 100%;
  left: 50%;
  background: #fff;
  transform: translateX(-50vw);
}

/* ==========================================================================
   #Quote
   ========================================================================== */
.wp-block-quote {
  padding-left: 1.33em;
  border-left: 2px solid;
}

.wp-block-pullquote {
  text-align: center;
}

.wp-block-quote p,
.wp-block-pullquote p {
  font-size: 1.25em;
  line-height: 1.33;
}
.wp-block-quote cite,
.wp-block-pullquote cite {
  display: block;
  font-size: 1em;
}
.wp-block-quote cite:not(:first-child),
.wp-block-pullquote cite:not(:first-child) {
  margin-top: 1.2em;
}
.wp-block-quote cite::before,
.wp-block-pullquote cite::before {
  content: "– ";
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #RESPONSIVE DISPLAY
   ========================================================================== */
.u-visible-xs-block {
  display: none !important;
}

.u-visible-xs-inline-flex {
  display: none !important;
}

.u-visible-xs-flex {
  display: none !important;
}

.u-visible-xs-table-cell {
  display: none !important;
}

@media (min-width: 0) {
  .u-visible-xs-block {
    display: block !important;
  }

  .u-visible-xs-inline-flex {
    display: inline-flex !important;
  }

  .u-visible-xs-flex {
    display: flex !important;
  }

  .u-visible-xs-table-cell {
    display: table-cell !important;
  }

  .u-hidden-xs {
    display: none !important;
  }
}
.u-visible-sm-block {
  display: none !important;
}

.u-visible-sm-inline-flex {
  display: none !important;
}

.u-visible-sm-flex {
  display: none !important;
}

.u-visible-sm-table-cell {
  display: none !important;
}

@media (min-width: 540px) {
  .u-visible-sm-block {
    display: block !important;
  }

  .u-visible-sm-inline-flex {
    display: inline-flex !important;
  }

  .u-visible-sm-flex {
    display: flex !important;
  }

  .u-visible-sm-table-cell {
    display: table-cell !important;
  }

  .u-hidden-sm {
    display: none !important;
  }
}
.u-visible-md-block {
  display: none !important;
}

.u-visible-md-inline-flex {
  display: none !important;
}

.u-visible-md-flex {
  display: none !important;
}

.u-visible-md-table-cell {
  display: none !important;
}

@media (min-width: 768px) {
  .u-visible-md-block {
    display: block !important;
  }

  .u-visible-md-inline-flex {
    display: inline-flex !important;
  }

  .u-visible-md-flex {
    display: flex !important;
  }

  .u-visible-md-table-cell {
    display: table-cell !important;
  }

  .u-hidden-md {
    display: none !important;
  }
}
.u-visible-lg-block {
  display: none !important;
}

.u-visible-lg-inline-flex {
  display: none !important;
}

.u-visible-lg-flex {
  display: none !important;
}

.u-visible-lg-table-cell {
  display: none !important;
}

@media (min-width: 1024px) {
  .u-visible-lg-block {
    display: block !important;
  }

  .u-visible-lg-inline-flex {
    display: inline-flex !important;
  }

  .u-visible-lg-flex {
    display: flex !important;
  }

  .u-visible-lg-table-cell {
    display: table-cell !important;
  }

  .u-hidden-lg {
    display: none !important;
  }
}
/*
** Utils - Hamburger
** ----------------------------------------------------------------------------- */
.u-hamburger {
  --hamburger-width: 2rem;
  --hamburger-height: 1.2rem;
  --hamburger-weight: 2px;
  --hamburger-color: currentColor;
  --hamburger-duration: 0.25s;
  --hamburger-radius: 3px;
  position: relative;
  width: var(--hamburger-width);
  height: var(--hamburger-width);
  cursor: pointer;
}
.u-hamburger:focus {
  outline: none;
}
.u-hamburger > * {
  position: relative;
  height: var(--hamburger-weight);
  transition-property: color, background-color;
  transition-duration: var(--hamburger-duration);
}
.has-overlay-open .u-hamburger > * {
  background-color: transparent;
}
.u-hamburger > *, .u-hamburger > *::before, .u-hamburger > *::after {
  display: block;
  width: 100%;
  background-color: var(--hamburger-color);
  border-radius: var(--hamburger-radius);
}
.u-hamburger > *::before, .u-hamburger > *::after {
  content: "";
  position: absolute;
  height: 100%;
  transition: top var(--hamburger-duration) var(--hamburger-duration), bottom var(--hamburger-duration) var(--hamburger-duration), width var(--hamburger-duration) var(--hamburger-duration), transform var(--hamburger-duration) 0s, background-color var(--hamburger-duration) 0s;
}
.has-overlay-open .u-hamburger > *::before, .has-overlay-open .u-hamburger > *::after {
  transition: top var(--hamburger-duration) 0s, bottom var(--hamburger-duration) 0s, width var(--hamburger-duration) 0s, transform var(--hamburger-duration) var(--hamburger-duration), background-color var(--hamburger-duration) var(--hamburger-duration);
}
.u-hamburger > *::before {
  top: calc((var(--hamburger-height) * 0.5 - var(--hamburger-weight) * 0.5) * -1);
}
.has-overlay-open .u-hamburger > *::before {
  top: 0;
  transform: rotate(45deg);
}
.u-hamburger > *::after {
  bottom: calc((var(--hamburger-height) * 0.5 - var(--hamburger-weight) * 0.5) * -1);
}
.has-overlay-open .u-hamburger > *::after {
  bottom: 0;
  transform: rotate(-45deg);
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/* ==========================================================================
   #ICON
   ========================================================================== */
.u-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.u-icon * {
  fill: currentColor;
  stroke: none;
}

/* Size variants
   ========================================================================== */
.u-icon--sm {
  width: 0.75em;
  height: 0.75em;
}

.u-icon--md {
  width: 1em;
  height: 1em;
}

.u-icon--lg {
  width: 1.33em;
  height: 1.33em;
}

/* ==========================================================================
   #LINE CLAMP
   ========================================================================== */
.u-line-clamp {
  display: -webkit-box;
  /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical;
  /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;
}
.u-line-clamp--1 {
  -webkit-line-clamp: 1;
}
.u-line-clamp--2 {
  -webkit-line-clamp: 2;
}
.u-line-clamp--3 {
  -webkit-line-clamp: 3;
}
.u-line-clamp--4 {
  -webkit-line-clamp: 4;
}
.u-line-clamp--5 {
  -webkit-line-clamp: 5;
}
.u-line-clamp--6 {
  -webkit-line-clamp: 6;
}

/* ==========================================================================
   #LOADER
   ========================================================================== */
.u-loader.is-loading {
  position: relative;
  display: flex;
  min-height: 3em;
  pointer-events: none;
}
.u-loader.is-loading > span {
  color: transparent;
}
.u-loader.is-loading::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 25;
  background-image: url("~assets/images/loader-dark.svg");
  background-position: center;
  background-repeat: no-repeat;
}

/* ==========================================================================
   #NOTICE
   ========================================================================== */
.u-notice {
  text-align: center;
  opacity: 0.5;
}

/* ==========================================================================
   #PLAYER
   ========================================================================== */
.u-player {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.u-player iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate3d(-50%, -50%, 0);
}

/* ==========================================================================
   #RESTRICTOR
   ========================================================================== */
.u-restrictor {
  display: inline-block;
}
.u-restrictor[href]:not(.c-button) {
  text-decoration: underline;
  text-decoration-thickness: 0.075em;
  text-underline-offset: 0.25em;
}
.u-restrictor[href]:not(.c-button):hover {
  color: #485cc7;
}
.u-restrictor > span {
  display: inline;
  white-space: nowrap;
}
.u-restrictor .u-icon {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: -0.2em;
}