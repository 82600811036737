/* ==========================================================================
   #CTA
   ========================================================================== */

.c-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: spacer-fixed(2);
  text-align: center;

  @include bp(md) {
    flex-direction: row;
    text-align: left;
  }
}

.c-cta__image {
  width: 100%;
  max-width: 37.5rem;

  @include bp(md) {
    width: 42.5%;
  }
}

.c-cta__content {
  flex: 1;

  @include bp(md) {
    max-width: 42rem;
  }
}

.c-cta__buttons {
  @include spacer-clamped(2);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: spacer-fixed(0.5) spacer-fixed(1);

  @include bp(md) {
    justify-content: start;
  }
}
