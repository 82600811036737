/* ==========================================================================
   #CARD
   ========================================================================== */

.c-card {
  display: flex;
  flex-direction: column;
  gap: spacer-fixed(0.66);
  flex: 1;
  padding: clamp(#{$global-spacer * 0.66}, 2.4vw, #{$global-spacer});
  background-color: $color-blue-lightest;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;
  overflow: hidden;

  &[href]:hover {
    background-color: darken($color-blue-lightest, 5%);
  }
}

.c-card__image {
  aspect-ratio: 3 / 2;
  border-radius: $global-radius;
  overflow: hidden;

  @include bp(lg) {
    aspect-ratio: 5 / 4;
  }

  *[src] {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-card__author {
  font-size: 1.3rem;
  text-transform: uppercase;

  + * {
    margin-top: spacer-fixed(-0.33);
  }
}

.c-card__excerpt {
  font-size: 1.5rem;
  line-height: 1.33;
}
