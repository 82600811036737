/* ==========================================================================
   #VALUES
   ========================================================================== */

.c-values {
  display: flex;
  gap: spacer-clamped(2);
  padding: spacer-clamped(2.5) spacer-clamped(3);
  background-color: $color-white;
  border-radius: $global-radius * 2;
  box-shadow: $global-box-shadow;
}

.c-values__separator {
  width: 1px;
  background-color: $color-shadow;
}

.c-values__nav {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: $global-spacer * 0.33;
  flex-basis: 27.5%;
  text-align: right;
}

.c-values__nav-item {
  font-size: 1.5rem;
  line-height: 1.33;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }

  &.is-active {
    opacity: 1;
  }
}

.c-values__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include bp(md) {
    align-items: start;
    text-align: left;
  }
}

.c-values__carousel {
  @include spacer-fixed(0.66);

  width: 100%;
  margin-left: calc(var(--column-gutter) * -1);
}

.c-values__item {
  display: flex;
  flex-direction: column;
  gap: $global-spacer * 0.66;
  width: 100%;
  padding-left: var(--column-gutter);
}

.c-values__buttons {
  @include spacer-fixed(1.33);

  display: flex;
  gap: var(--column-gutter);
}

.c-values__button {
  padding: 0.125em;
  background-color: $color-white;
  border: 2px solid;
  border-radius: 50%;
  font-size: 1.5em;
  transition: filter $global-transition-speed;

  &:hover {
    filter: brightness(90%);
  }
}
