/* ==========================================================================
   #ARTICLE
   ========================================================================== */

.c-article {
  @extend %copy;

  width: 100%;
  max-width: var(--content-width);
  margin-right: auto;
  margin-left: auto;

  .alignwide {
    @include align-full(true, true);

    &.wp-block-separator,
    &.wp-block-acf-hero,
    &.wp-block-acf-products,
    &.wp-block-acf-section {
      @include align-full(false, true);
    }
  }

  .alignfull {
    @include align-full(false, true);

    &.wp-block-separator,
    &.wp-block-acf-hero,
    &.wp-block-acf-products,
    &.wp-block-acf-section {
      @include align-full(false, false);
    }
  }
}
