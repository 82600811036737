/* ==========================================================================
   #VENDOR - FLICKITY
   ========================================================================== */

/* prevent page scrolling when flickity is fullscreen */
.is-flickity-fullscreen {
  overflow: hidden;
}

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  &.is-draggable {
    .flickity-viewport {
      cursor: grab;

      &.is-pointer-down {
        cursor: grabbing;
      }
    }
  }

  &.is-fade .flickity-slider {
    > * {
      pointer-events: none;
    }

    > .is-selected {
      pointer-events: auto;
    }
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.25s;

  .is-fullscreen & {
    transition: none;
  }
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
