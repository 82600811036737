/* ==========================================================================
   #CONTAINER
   ========================================================================== */

%container {
  width: 100%;
  max-width: min(var(--container-width), 100% - var(--container-gutter) * 2);
  margin-right: auto;
  margin-left: auto;
}
