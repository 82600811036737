/* ==========================================================================
   #BENEFIT
   ========================================================================== */

.c-benefit {
  display: flex;
  align-items: center;
  gap: spacer-clamped(1.33);
  padding: spacer-fixed(1);
  background-color: $color-white;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;

  @include bp(lg) {
    padding-top: spacer-fixed(1.5);
    padding-bottom: spacer-fixed(1.5);
  }
}

.c-benefit__icon {
  font-size: 3.33em;

  @include bp(lg) {
    font-size: 5.33em;
  }

  svg {
    height: 1em;
    width: 1em;
  }
}

.c-benefit__content {
  flex: 1;
}

.c-benefit__description {
  @include spacer-fixed(0.33);

  font-size: 1.3rem;
  line-height: 1.33;

  @include bp(md) {
    font-size: 1.5rem;
  }
}
