/* ==========================================================================
   #SOCIALS
   ========================================================================== */

.c-socials {
  display: flex;
  gap: 0.75em;
}

.c-socials__item {
  flex-shrink: 0;
}

.c-socials__link {
  display: flex;
  align-items: center;
  padding: 0.25em;
  background-color: $color-white;
  border-radius: 50%;
  color: $color-navy;
  font-size: 1.2em;
  line-height: 1;

  @include bp(lg) {
    font-size: 1.33em;
  }

  &:hover {
    opacity: 0.75;
  }
}
