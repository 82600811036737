/*
** Utils - Hamburger
** ----------------------------------------------------------------------------- */

.u-hamburger {
  // Variables
  --hamburger-width: 2rem;
  --hamburger-height: 1.2rem;
  --hamburger-weight: 2px;
  --hamburger-color: currentColor;
  --hamburger-duration: 0.25s;
  --hamburger-radius: 3px;

  position: relative;
  width: var(--hamburger-width);
  height: var(--hamburger-width);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  > * {
    position: relative;
    height: var(--hamburger-weight);
    transition-property: color, background-color;
    transition-duration: var(--hamburger-duration);

    .has-overlay-open & {
      background-color: transparent;
    }

    &,
    &::before,
    &::after {
      display: block;
      width: 100%;
      background-color: var(--hamburger-color);
      border-radius: var(--hamburger-radius);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      transition: top var(--hamburger-duration) var(--hamburger-duration),
        bottom var(--hamburger-duration) var(--hamburger-duration),
        width var(--hamburger-duration) var(--hamburger-duration), transform var(--hamburger-duration) 0s,
        background-color var(--hamburger-duration) 0s;

      .has-overlay-open & {
        // Revert the delays
        transition: top var(--hamburger-duration) 0s, bottom var(--hamburger-duration) 0s,
          width var(--hamburger-duration) 0s, transform var(--hamburger-duration) var(--hamburger-duration),
          background-color var(--hamburger-duration) var(--hamburger-duration);
      }
    }

    &::before {
      top: calc(((var(--hamburger-height) * 0.5) - (var(--hamburger-weight) * 0.5)) * -1);

      .has-overlay-open & {
        top: 0;
        transform: rotate(45deg);
      }
    }

    &::after {
      bottom: calc(((var(--hamburger-height) * 0.5) - (var(--hamburger-weight) * 0.5)) * -1);

      .has-overlay-open & {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}
