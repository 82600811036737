/* ==========================================================================
   #TABLE
   ========================================================================== */

.c-table {
  margin-top: spacer-clamped(2.5);
  margin-bottom: spacer-clamped(2.5);
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
}

.c-table__row {
  border-bottom: 1px solid $color-shadow;
}

.c-table__cell {
  padding: clamp(#{$global-spacer * 0.66}, 2.4vw, #{$global-spacer}) 0;
  text-align: left;
  vertical-align: middle;

  &:not(:first-child) {
    padding-left: spacer-clamped(2);
    text-align: right;
    white-space: nowrap;
  }

  &--dimmed {
    color: $color-gray;
    font-size: 0.833em;

    @include bp(lg) {
      font-size: 1em;
    }
  }

  &--cta {
    color: $color-navy;
  }
}

.c-table__icon {
  display: block;
  width: 1em;
  height: 1em;
}
