/* ==========================================================================
   #POPUP
   ========================================================================== */

.c-popup {
  position: fixed;
  inset: 0;
  z-index: $layer-popup;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(-300%);
  transition: transform 0s $global-transition-speed;

  .has-popup-open & {
    transform: translateX(0);
    pointer-events: all;
    transition: none;
  }
}

.c-popup__close {
  @extend %heading;
  @extend %heading-h3;

  position: absolute;
  top: calc(var(--container-gutter) + 1em);
  right: calc(var(--container-gutter) + 1em);
  z-index: $layer-base;
  padding: 1em;
  margin: -1em;

  [data-layout='video'] & {
    font-size: spacer-clamped(1.5);
  }
}

.c-popup__content {
  position: absolute;
  inset: 50% auto auto 50%;
  opacity: 0;
  margin-top: 5%;
  color: $color-white;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity $global-transition-speed * 1.5, margin $global-transition-speed * 1.5;

  .has-popup-open & {
    opacity: 1;
    margin-top: 0;
  }

  &[data-layout='content'] {
    width: calc(100% - (var(--container-gutter) * 2));
    max-height: calc(100% - (var(--container-gutter) * 2));
    max-width: calc(var(--content-width) + (2 * var(--container-gutter)));
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &[data-layout='video'] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: calc(var(--container-width) + (2 * var(--container-gutter)));
    padding: var(--container-gutter);
  }
}

.c-popup__inner {
  [data-layout='video'] &,
  [data-layout='video'] & [data-popup-video] {
    display: contents;

    iframe,
    video {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
