/* ==========================================================================
   #Image
   ========================================================================== */

.wp-block-image {
  // Alignments
  &.alignnone,
  &.aligncenter,
  &.alignright,
  &.alignleft {
    @include spacer-relative;

    max-width: 100%;
    clear: both;
    margin: 0;
  }

  &.alignright,
  &.alignleft {
    max-width: 45%;
    margin-top: 0;
    margin-bottom: 1em;

    @include bp(md) {
      margin-bottom: 2em;
    }
  }

  &.alignright {
    float: right;
    margin-left: 2em;
  }

  &.alignleft {
    float: left;
    margin-right: 2em;

    @include bp(md) {
      margin-right: 10%;
      margin-left: calc(var(--container-gutter) * -1);
    }
  }

  &.aligncenter {
    margin-left: auto;
    margin-right: auto;
  }

  &.alignwide,
  &.alignfull {
    &:first-child {
      margin-top: calc(var(--spacer-top) * -1);

      &::before {
        content: '';
        display: block;
        height: 1px;
        width: calc(100vw - var(--scrollbar-width, 0px));
        position: absolute;
        bottom: 100%;
        left: 50%;
        background: $color-white;
        transform: translateX(-50vw);
      }
    }
  }
}
