/* ==========================================================================
   #LOGO
   ========================================================================== */

.c-logo {
  display: inline-block;

  &:hover {
    opacity: 0.75;
  }

  // Type variants
  &--text {
    font-weight: 500;
    letter-spacing: 0.125em;
  }

  &--image {
    svg {
      display: block;
      height: 1em;
    }

    * {
      fill: currentColor;
      stroke: none;
    }
  }

  // Size variants
  &--md {
    font-size: clamp(2.4rem, 5vw, 4rem);
  }

  &--lg {
    font-size: 4rem;
  }
}
