/* ==========================================================================
   #COPY
   ========================================================================== */

%copy {
  @for $i from 1 through 6 {
    h#{$i}:not(.c-heading) {
      @include spacer-relative;

      @extend %heading !optional;
      @extend %heading-h#{$i} !optional;
    }
  }

  h1:not(.c-heading) {
    @include spacer-relative(0.75, bottom);
    @include spacer-relative(0.25);
  }

  h2:not(.c-heading) {
    @include spacer-relative(0.5, bottom);
  }

  h3:not(.c-heading) {
    @include spacer-relative(1, bottom);
  }

  h4:not(.c-heading) {
    @include spacer-relative(1.5);
  }

  ul,
  ol {
    margin-left: 1em;

    li {
      padding-left: 0.5em;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style-type: decimal;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    display: block;
    width: 100%;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  mark {
    background-color: $color-blue;
  }

  p,
  ul,
  ol,
  address {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:empty) {
      @include spacer-relative;
    }
  }

  li {
    @include spacer-relative(0.75);
  }

  a:not([class]),
  a[class=''] {
    &:hover {
      color: $color-blue;
    }
  }

  [class*='wp-block'] figcaption {
    @include spacer-relative;
  }
}
