/* ==========================================================================
   #MEDIA
   ========================================================================== */

.c-media {
  width: 100%;

  // Aspect variants
  &--1-1 {
    aspect-ratio: 1 / 1;
  }

  &--3-2 {
    aspect-ratio: 3 / 2;
  }

  &--16-9 {
    aspect-ratio: 16 / 9;
  }
}

.c-media__object {
  position: relative;
  z-index: $layer-base;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &[href],
  &[data-popup] {
    &:hover {
      opacity: 0.9;
    }
  }

  picture {
    display: contents;
  }

  img,
  video,
  iframe {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
  }

  // Object fit variants
  &--cover {
    img,
    video,
    iframe {
      object-fit: cover;
    }
  }

  &--contain {
    img,
    video,
    iframe {
      object-fit: contain;
    }
  }
}

.c-media__play {
  position: absolute;
  z-index: $layer-base;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-size: clamp(#{$global-spacer * 2}, 6vw, #{$global-spacer * 2.5});
  transition: transform $global-transition-speed;

  button:hover & {
    transform: scale(1.1);
  }

  .u-icon {
    filter: drop-shadow($global-box-shadow);
  }
}
