/* ==========================================================================
   #BODY
   ========================================================================== */

.o-body {
  @include dimmer(('has-popup-open', 'has-overlay-open', 'is-loading'), $layer-popup - 1, $color-navy, 0.85);

  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: $color-bg;
  color: $color-text;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $font-line-height;

  &.is-loading::before {
    content: '';
    position: fixed;
    inset: 0;
    z-index: $layer-popup;
    background-image: url('~assets/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}
