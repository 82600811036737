/* ==========================================================================
   #MENU - SECONDARY
   ========================================================================== */

.c-menu-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: spacer-relative(0.66) spacer-relative(1);

  @include bp(md) {
    flex-direction: row-reverse;
    justify-content: end;
  }
}

.c-menu-secondary__link {
  &:not([class*='button']):hover {
    opacity: 0.75;
  }
}
