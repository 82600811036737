/* ==========================================================================
   #Button
   ========================================================================== */

.wp-block-button__link[class] {
  @extend %button;

  min-width: 16rem;

  .is-style-outline &:not(.has-color) {
    color: $color-blue;

    &:hover {
      color: $color-white;
    }
  }

  &:not(.has-background) {
    border-color: $color-blue;
  }
}
