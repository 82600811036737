/* ==========================================================================
   #HEADING
   ========================================================================== */

%heading {
  font-weight: 700;
  font-size: clamp(var(--minFontSize), var(--scaler, 1vw), var(--maxFontSize));
}

%heading-h1 {
  --minFontSize: 4.2rem;
  --maxFontSize: 10.8rem;
  --scaler: 7.5vw;

  line-height: 1.05;
}

%heading-h2 {
  --minFontSize: 3.6rem;
  --maxFontSize: 5.4rem;
  --scaler: 5.4vw;

  line-height: 1.1;
}

%heading-h3 {
  --minFontSize: 3rem;
  --maxFontSize: 4.2rem;
  --scaler: 4.2vw;

  line-height: 1.15;
}

%heading-h4 {
  --minFontSize: 2.4rem;
  --maxFontSize: 3rem;
  --scaler: 3vw;

  line-height: 1.2;
}

%heading-h5 {
  --minFontSize: 1.8rem;
  --maxFontSize: 1.8rem;

  line-height: 1.22;
}

%heading-h6 {
  --minFontSize: 1.5rem;
  --maxFontSize: 1.5rem;

  line-height: 1.33;
}
