/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  @extend %heading;

  &--thin {
    font-weight: normal;
  }
}

/* Size variants
   ========================================================================== */

@for $i from 1 through 6 {
  .c-heading--h#{$i} {
    @extend %heading-h#{$i} !optional;
  }
}
