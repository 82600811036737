/* ==========================================================================
   #PANEL
   ========================================================================== */

.c-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: spacer-fixed(2);

  @include bp(sm) {
    text-align: center;
  }

  @include bp(md) {
    text-align: left;
  }

  // Alignment variants
  &--left {
    @include bp(md) {
      flex-direction: row;
    }
  }

  &--right {
    @include bp(md) {
      flex-direction: row-reverse;
    }
  }
}

.c-panel__image {
  width: 100%;
  max-width: 42.5rem;
  position: relative;

  @include bp(md) {
    width: 50%;
  }

  // Object fit variants
  &--contain {
    @include bp(md) {
      aspect-ratio: 1 / 1;

      img,
      video,
      iframe {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.c-panel__content {
  flex: 1;

  @include bp(md) {
    max-width: 35rem;
  }
}
