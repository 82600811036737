/* ==========================================================================
   #LEAD
   ========================================================================== */

.c-lead {
  @extend %wrapper-constrain-width;

  display: flex;
  flex-direction: column;

  // Alignment variants
  &--left {
    align-items: start;
    text-align: left;
  }

  &--center {
    align-items: center;
    text-align: center;
  }

  &--right {
    align-items: end;
    text-align: right;
  }
}

.c-lead__title {
  @include spacer-fixed(0.33);
}

.c-lead__description {
  @include spacer-clamped(1);

  font-size: clamp(1.8rem, 3vw, 3rem);
  line-height: 1.2;
}

.c-lead__button {
  @include spacer-clamped(2);
}
