/* ==========================================================================
   #Quote
   ========================================================================== */

.wp-block-quote {
  padding-left: 1.33em;
  border-left: 2px solid;
}

.wp-block-pullquote {
  text-align: center;
}

.wp-block-quote,
.wp-block-pullquote {
  p {
    font-size: 1.25em;
    line-height: 1.33;
  }

  cite {
    @include spacer-relative;

    display: block;
    font-size: 1em;

    &::before {
      content: '– ';
    }
  }
}
