/* ==========================================================================
   #SECTION
   ========================================================================== */

.o-section {
  position: relative;
  padding-top: spacer-clamped(5);
  padding-bottom: spacer-clamped(5);

  // Padding variants
  &--pt-0 {
    padding-top: 0;
  }

  &--pb-0 {
    padding-bottom: 0;
  }

  // Style variants
  &--style-white {
    background-color: $color-white;
  }

  &--style-indigo {
    background-color: $color-blue-lightest;
  }

  &--style-gradient-top {
    background-image: linear-gradient(to top, $color-blue-lightest, $color-white);
  }

  &--style-gradient-bottom {
    background-image: linear-gradient(to bottom, $color-blue-lightest, $color-white);
  }

  &--style-gradient-shape {
    background-image: linear-gradient(to bottom, $color-blue-lightest, $color-white);

    @include bp(md) {
      background-color: $color-blue-lightest;
      background-image: url('~assets/images/separator.svg');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &--style-hero {
    background-image: linear-gradient(
      135deg,
      $color-beige 0%,
      $color-white 42%,
      $color-blue-lightest 70%,
      $color-blue-light 100%
    );
  }
}
