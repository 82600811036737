/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid {
  display: grid;
  gap: var(--column-gutter);

  // Grid variants
  @each $breakpoint, $opts in $breakpoints {
    @for $i from 1 through 6 {
      @include bp($breakpoint) {
        &--#{$breakpoint}-#{$i} {
          grid-template-columns: repeat($i, 1fr);
        }
      }
    }
  }
}

.o-grid__item {
  width: 100%;

  &--full {
    grid-column: 1 / -1;
  }

  // Grid variants
  @each $breakpoint, $opts in $breakpoints {
    @for $i from 1 through 6 {
      @include bp($breakpoint) {
        &--#{$breakpoint}-#{$i} {
          grid-column: span $i;
        }
      }
    }
  }
}
