/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

@font-face {
  font-family: 'ASICS Font 3.0';
  font-weight: normal;
  font-display: swap;
  src: url('~assets/fonts/ASICSFont3.0-Regular.woff2') format('woff2'),
    url('~assets/fonts/ASICSFont3.0-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ASICS Font 3.0';
  font-weight: bold;
  font-display: swap;
  src: url('~assets/fonts/ASICSFont3.0-Bold.woff2') format('woff2'),
    url('~assets/fonts/ASICSFont3.0-Bold.woff') format('woff');
}
