// ==========================================================================
// #CLEARFIX
// ==========================================================================

@mixin clearfix() {
  &::after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}
