/* ==========================================================================
   #BOTTOM
   ========================================================================== */

.c-bottom {
  padding-top: spacer-clamped(0.5);
  padding-bottom: spacer-clamped(0.5);
  background-color: $color-navy;
  color: $color-white;
  font-size: 1.2rem;
}

.c-bottom__inner {
  @extend %wrapper;
  @extend %wrapper-wrappable;

  row-gap: spacer-clamped(1);
}
