/* ==========================================================================
   #TILE
   ========================================================================== */

.c-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $global-spacer * 0.66;
  flex: 1;
  padding: clamp(#{$global-spacer}, 3.6vw, #{$global-spacer * 1.5});
  background-color: $color-blue;
  border-radius: $global-radius;
  box-shadow: $global-box-shadow;
  color: $color-white;
  text-align: center;
  overflow: hidden;

  &[href]:hover {
    background-color: darken($color-blue, 5%);
  }
}

.c-tile__icon {
  width: 1em;
  height: 1em;
  font-size: 4rem;
}

.c-tile__description {
  font-size: 1.5rem;
  line-height: 1.33;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: $global-spacer * 0.25;
  }
}

.c-tile__link {
  margin-top: auto;
  transition: color $global-transition-speed;

  [href]:hover & {
    color: $color-blue-light;
  }
}
