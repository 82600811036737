// ==========================================================================
// #DIMMER
// ==========================================================================

// Add negative spacing to make the element full width
//
// @param {Boolean} $container  - Contain the max width
// @param {Boolean} $gutters  - Add paddings at the sides
@mixin align-full($container: false, $gutters: false) {
  width: calc(100vw - var(--scrollbar-width, 0px) #{if($gutters, '- calc(var(--container-gutter) * 2)', null)});
  margin-left: 50%;
  transform: translateX(-50%);

  @if ($container) {
    max-width: var(--container-width);
  }

  .acf-block-preview & {
    width: auto;
    margin-left: 0;
    transform: none;
  }
}
