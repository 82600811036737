/* ==========================================================================
   #Embed
   ========================================================================== */

.wp-block-embed {
  .wp-block-embed__wrapper {
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    > * {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
}
