/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  --button-primary-bg-color: #{$color-white};
  --button-primary-color: #{$color-navy};
  --button-secondary-bg-color: #{$color-white};
  --button-secondary-color: #{$color-navy};
  --button-tertiary-bg-color: #{$color-navy};
  --button-tertiary-bd-color: #{$color-white};
  --button-tertiary-color: #{$color-white};

  margin-top: auto;
  padding-top: spacer-clamped(2);
  padding-bottom: spacer-clamped(2);
  background-color: $color-navy;
  color: $color-white;
}

.c-footer__inner {
  @extend %wrapper;
  @extend %wrapper-wrappable;
}

.c-footer__links {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: spacer-relative(1);

  @include bp(md) {
    align-items: start;
  }
}

.c-footer__menu {
  flex: 1;
}
