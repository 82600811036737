// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 2.4rem !default;
$global-spacer: 2.4rem !default;
$global-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35) !default;
$global-transition-speed: 300ms !default;
$global-transition: all $global-transition-speed ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial',
  sans-serif !default;
$font-asics: 'ASICS Font 3.0', #{$font-sans-serif} !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  xs: 0,
  sm: 540px,
  md: 768px,
  lg: 1024px,
);

/* Responsive display values
   ========================================================================== */

$display-values: (block, inline-flex, flex, table-cell);

/* Layers
   ========================================================================== */

$layer-negative: -1 !default;
$layer-neutral: 0 !default;
$layer-base: 1 !default;
$layer-utils: 5 !default;
$layer-dropdown: 10 !default;
$layer-overlay: 15 !default;
$layer-header: 20 !default;
$layer-popup: 25 !default;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-black: #000;
$color-white: #fff;
$color-gray: #6a6a6a;
$color-shadow: #ccc;
$color-beige: #ffecdc;
$color-navy: #001e62;
$color-blue: #485cc7;
$color-blue-light: #b6b8dc;
$color-blue-lightest: #e4e5f3;

// Text
$color-bg: $color-white;
$color-text: $color-navy;

// Statuses
$color-success: #66a000 !default;
$color-error: #dc706c !default;

/* Typography
   ========================================================================== */

$font-family: $font-asics;
$font-size: 1.8rem;
$font-weight: normal;
$font-line-height: 1.2;

/* GLOBAL CSS VARIABLES
   ========================================================================== */

:root {
  --column-gutter: clamp(1.6rem, 2.5vw, 2.4rem);
  --container-width: 162rem;
  --container-gutter: clamp(2.4rem, 5vw, 6rem);
  --content-width: 88rem;
}
