/* ==========================================================================
   #LABEL
   ========================================================================== */

.c-label {
  color: var(--label-color, #{$color-blue});
  font-size: 1.1rem;
  line-height: 1.5;
  letter-spacing: 0.5em;
  text-transform: uppercase;

  @include bp(md) {
    font-size: 1.5rem;
    line-height: 1.33;
  }

  // Size variants
  &--lg {
    @include bp(lg) {
      font-size: 1.8rem;
      line-height: 1.22;
    }
  }
}
