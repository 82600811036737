/* ==========================================================================
   #GALLERY
   ========================================================================== */

.c-gallery {
  display: grid;
  gap: var(--column-gutter);
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  @include bp(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-gallery__image {
  aspect-ratio: 3 / 2;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  [src] {
    object-fit: cover;
  }

  @include bp(lg) {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;

    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      grid-column: span 2;
      aspect-ratio: 2 / 1;
    }
  }
}
