/* ==========================================================================
   #WRAPPER
   ========================================================================== */

%wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacer-clamped(2);
}

%wrapper-wrappable {
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  @include bp(md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

%wrapper-constrain-width {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include bp(md) {
    max-width: 82.5%;
  }
}
