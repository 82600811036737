/* ==========================================================================
   #MENU - PRIMARY
   ========================================================================== */

.c-menu-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: spacer-clamped(2) spacer-clamped(2);

  @include bp(md) {
    flex-direction: row;
  }

  @include bp-down(md) {
    --button-primary-bg-color: #{$color-white};
    --button-primary-color: #{$color-navy};
    --button-padding: 0.888em 1.666em;
  }
}

.c-menu-primary__link {
  @include bp(md) {
    font-weight: bold;
  }

  &[class*='button'] {
    @include bp-down(md) {
      margin-top: 1.5em;
    }
  }

  &:not([class*='button']) {
    font-size: 3rem;

    @include bp(md) {
      font-size: 1em;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}
