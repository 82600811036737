/* ==========================================================================
   #ICON
   ========================================================================== */

.u-icon {
  display: block;
  width: 100%;
  height: 100%;

  * {
    fill: currentColor;
    stroke: none;
  }
}

/* Size variants
   ========================================================================== */

.u-icon--sm {
  width: 0.75em;
  height: 0.75em;
}

.u-icon--md {
  width: 1em;
  height: 1em;
}

.u-icon--lg {
  width: 1.33em;
  height: 1.33em;
}
