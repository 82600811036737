/* ==========================================================================
   #COMMON
   ========================================================================== */

.wp-block-buttons,
.wp-block-code,
.wp-block-table,
.wp-block-latest-posts .wp-block-subhead,
.wp-block-categories,
.wp-block-verse,
.wp-block-preformatted {
  &[class] {
    @include spacer-clamped(2, 'top', 'margin');

    margin-top: 0;
    margin-bottom: 0;
  }
}

.wp-block-image,
.wp-block-cover,
.wp-block-gallery,
.wp-block-embed,
.wp-block-quote,
.wp-block-pullquote,
.wp-block-columns,
.wp-block-text-columns,
.wp-block-separator {
  &[class] {
    @include spacer-clamped(4, 'top', 'margin');
    @include spacer-clamped(4, 'bottom', 'margin');

    margin-top: 0;
    margin-bottom: 0;
  }
}

.wp-block-acf-benefits,
.wp-block-acf-cta,
.wp-block-acf-gallery,
.wp-block-acf-head,
.wp-block-acf-jobs,
.wp-block-acf-lead,
.wp-block-acf-media,
.wp-block-acf-panel,
.wp-block-acf-posts,
.wp-block-acf-testimonial,
.wp-block-acf-tiles,
.wp-block-acf-values {
  &[class] {
    @include spacer-clamped(5, 'top', 'margin');
    @include spacer-clamped(5, 'bottom', 'margin');

    margin-top: 0;
    margin-bottom: 0;
  }

  .wp-block-acf-head + & {
    @include spacer-clamped(-3, 'top', 'margin');
  }

  .wp-block-acf-lead.alignwide + & {
    @include spacer-clamped(-2.5, 'top', 'margin');
  }
}

.wp-block-acf-panel + .wp-block-acf-panel {
  &[class] {
    @include bp(md) {
      @include spacer-clamped(-2.5, 'top', 'margin');
    }
  }
}

.wp-block-acf-jobs {
  overflow: hidden;
}
