/* ==========================================================================
   #CAROUSEL
   ========================================================================== */

.c-carousel {
  display: grid;
  gap: var(--column-gutter);

  // Grid variants
  @each $breakpoint, $columns in (sm: 1, md: 2, lg: 3) {
    @include bp($breakpoint) {
      grid-template-columns: repeat($columns, 1fr);
    }
  }

  &.flickity-enabled {
    display: block;
    width: calc(100% + var(--column-gutter));
    margin: calc(var(--column-gutter) * -0.5);
  }

  .flickity-viewport {
    overflow: visible;
    transition: height 0s;
  }
}

.c-carousel__item {
  display: flex;

  .flickity-enabled & {
    width: calc(75vw - (var(--container-gutter) * 2) + var(--column-gutter));
    max-width: calc(28rem + var(--column-gutter));
    padding: calc(var(--column-gutter) * 0.5);

    @include bp(lg) {
      width: 33.333%;
    }
  }

  .flickity-resize & {
    min-height: 100%;
  }
}
