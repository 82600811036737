/* ==========================================================================
   #BUTTON
   ========================================================================== */

%button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: $layer-base;
  min-width: var(--button-min-width, 16.5rem);
  padding: var(--button-padding, 0.75em 2em);
  border: 2px solid transparent;
  border-radius: 100vmin;
  font-size: var(--button-font-size, 0.833em);
  font-weight: bold;
  line-height: var(--button-line-height, 1);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transition: filter $global-transition-speed;

  &:not([disabled]):hover {
    filter: brightness(90%);
  }
}

%button-primary {
  background-color: var(--button-primary-bg-color, #{$color-blue});
  border-color: var(--button-primary-bd-color, transparent);
  box-shadow: var(--button-primary-shadow, #{$global-box-shadow});
  color: var(--button-primary-color, #{$color-white});
}

%button-secondary {
  background-color: var(--button-primary-bg-color, #{$color-navy});
  border-color: var(--button-primary-bd-color, transparent);
  box-shadow: var(--button-primary-shadow, #{$global-box-shadow});
  color: var(--button-primary-color, #{$color-white});
}

%button-tertiary {
  background-color: var(--button-tertiary-bg-color, #{$color-white});
  border-color: var(--button-tertiary-bd-color, #{$color-navy});
  color: var(--button-tertiary-color, #{$color-navy});
}
