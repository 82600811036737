/* ==========================================================================
   #MENU - TERTIARY
   ========================================================================== */

.c-menu-tertiary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: spacer-relative(0.25) spacer-relative(1.5);
}

.c-menu-tertiary__link {
  &:hover {
    opacity: 0.75;
  }
}
