/* ==========================================================================
   #WRAPPER
   ========================================================================== */

.o-wrapper {
  position: relative;
  z-index: $layer-base;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
