/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: sticky;
  top: 0;
  z-index: $layer-overlay + 1;
  padding-top: spacer-clamped(1.25);
  padding-bottom: spacer-clamped(1.25);
  background-color: rgba($color-white, 0.95);
  box-shadow: 0 2px 0 0 $color-blue-lightest;
  backdrop-filter: blur(5px);

  @include bp-down(md) {
    .has-overlay-open & {
      color: $color-white;
    }
  }
}

.c-header__inner {
  @extend %wrapper;

  position: relative;
  z-index: $layer-overlay + 1;
}

.c-header__hamburger {
  @include bp-down(md) {
    .has-overlay-open & {
      color: $color-blue-light;
    }
  }
}
