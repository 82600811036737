/* ==========================================================================
   #PLAYER
   ========================================================================== */

.u-player {
  height: 100%;
  width: 100%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
  }
}
