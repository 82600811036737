/* ==========================================================================
   #RESTRICTOR
   ========================================================================== */

.u-restrictor {
  display: inline-block;

  &[href]:not(.c-button) {
    text-decoration: underline;
    text-decoration-thickness: 0.075em;
    text-underline-offset: 0.25em;

    &:hover {
      color: $color-blue;
    }
  }

  > span {
    display: inline;
    white-space: nowrap;
  }

  .u-icon {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: -0.2em;
  }
}
