/* ==========================================================================
   #HEAD
   ========================================================================== */

.c-head {
  @extend %wrapper;

  text-align: center;

  // Alignment variants
  &--left {
    @include bp(md) {
      text-align: left;
    }
  }

  &--center {
    @include bp(md) {
      text-align: center;
    }
  }

  &--right {
    @include bp(md) {
      text-align: right;
    }
  }
}

.c-head__title {
  width: 100%;
}
