/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */

.c-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-testimonial__content {
  @include spacer-fixed(1.33);
}

.c-testimonial__quote {
  font-size: clamp(1.8rem, 3vw, 3rem);
  line-height: 1.2;
}
