/* ==========================================================================
   #LOADER
   ========================================================================== */

.u-loader {
  &.is-loading {
    position: relative;
    display: flex;
    min-height: 3em;
    pointer-events: none;

    > span {
      color: transparent;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: $layer-popup;
      background-image: url('~assets/images/loader-dark.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
